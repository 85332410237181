import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,addClass,on,findIn, findAllIn, hasClass} from '@elements/dom-utils';

const selectors = {
    base: '.js-header',
    hero: '.hero'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let header = findIn(selectors.base, document.body);
        let hero = findIn(selectors.hero, document.body);
        let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

        window.addEventListener('scroll', function(){
            if (window.scrollY > 120){
                addClass('header--scroll', header);
                if(hero) {
                    removeClass('header--transparent', header);
                }
            }
            if (window.scrollY === 0){
                removeClass('header--scroll', header);
                if(hero) {
                    addClass('header--transparent', header);
                }
            }

            // Get the current scroll position
            let currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (lastScrollTop > currentScrollTop) {
                removeClass('header--scroll', header);
            }
            lastScrollTop = currentScrollTop;
        });
    });
}
